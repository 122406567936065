const polishRule = (number: number) => {
  if (number > 20) {
    const lastDigit = parseInt(number.toString().at(-1)!);

    if (lastDigit > 1 && lastDigit < 5) {
      return 1;
    }
  }

  if (number > 1 && number < 5) {
    return 1;
  }

  if (number === 1) {
    return 0;
  }

  return 2;
};

export default {
  legacy: false,
  locale: 'pl-PL',
  defaultLocale: 'pl-PL',
  pluralRules: {
    'pl-PL': polishRule,
  },
};
